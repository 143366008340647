.nav-wrapper {
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;
    width: 100%;
    z-index: 1000;
}

nav {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 2rem;
}

nav a {
    text-decoration: none;
    color: #2c3e50;
    
    font-weight: 500;
    transition: color 0.3s;
}

nav a:hover {
    color: #3498db;
}

.hamburger {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 10; /* Ensure it stays on top */
}

@media (max-width: 768px) {
    .hamburger {
        display: block; /* Show hamburger icon on smaller screens */
    }

    nav ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #faf6f1;
        transition: max-height 0.3s ease;
        max-height: 0;
        overflow: hidden;
      }
    
      nav ul.show {
        max-height: 1000px; /* large enough to show all links */
      }

    nav ul li {
        padding: 10px 0; /* Add spacing for better readability */
    }

    .nav-wrapper {
        position: relative;
    }
}


