/* src/styles/global.css */

/* Reset default margins and paddings */
*, *::before, *::after {
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Flex container for the entire app */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: white;
    line-height: 1.6;
}

/* Main content area */
main {
    flex: 1; /* Allows main to grow and fill available space */
    width: 100%; /* Ensures main takes full width */
    padding: 0rem; /* Optional: Adjust padding as needed */
    padding-top: 0px;
    margin-top: 0px;
    background: white;
}

.white-section{
    background: #ffffff;
    padding: 1rem;
}

.white-section h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 3rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.white-section p{
    text-align: center;
}

.white-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #3498db;
}

.color-section{
    background: #faf6f1;
    padding: 1rem;
}

.color-section h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 3rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.color-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #3498db;
}


.section-container{
    max-width: 1200px;
    margin: 3rem auto;
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

 /* Media Queries */
 @media (max-width: 768px) {
    .contact-section {
      padding: 2rem 1rem;
    }
  }
  



