/* Timeline Styles */
.timeline-section {
    position: fixed;
    top:25%;
    left: 2rem; /* Positioning the timeline to the left */
    width: 300px;
    padding-top: 2rem;
    height: calc(80vh); 
    overflow-y: auto;
    z-index: 1200;
}

.timeline {
    position: relative;
    margin-left: 2rem;
    z-index: 2000;
}

.timeline::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 100%;
    background: #e5e7eb;
}

.timeline-item {
    position: absolute;
    left: -8px;
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.timeline-item:hover {
    transform: translateX(5px);
}

.timeline-period {
    position: absolute;
    left: -2px;
    width: 6px;
    background: #3b82f6;
    border-radius: 3px;
    opacity: 0.3;
    transition: all 0.3s ease;
}

.timeline-period.active {
    opacity: 1;
    width: 8px;
}

.timeline-content {
    position: absolute;
    left: 20px;
    background: white;
    padding: 0rem;
    border-radius: 3px;
    box-shadow: 0 3px 4px rgba(0,0,0,0.1);
    font-size: 0.5rem;
    width: 230px;
    opacity: 0.7;
    transition: all 0.3s ease;
    z-index: 2000;
}

.timeline-content.active {
    opacity: 1;
    transform: translateX(5px);
}

.timeline-content h3 {
    font-size: 0.7rem;
    padding-left: 1rem;
}

.timeline-content p {
    font-size: 0.7rem;
    color: #666;
    padding-left: 1rem;
}

@media (max-width: 1500px) {

    .timeline-section {
        display: none;
    }
}