/* About Container */
.about-container {
    max-width: 1200px;
    margin: 3rem auto;
    padding: 0 2rem;
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
}

.about-text {
    flex: 1;
    min-width: 300px;
}

.about-image {
    flex: 1;
    min-width: 300px;
    position: relative;
}

.about-image img {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    /* background: #f0f0f0; */
}

h1, h2 {
    color: #2c3e50;
}

.about-section {
    margin-bottom: 2rem;
}

.about-section h2 {
    color: #3498db;
    margin-bottom: 1rem;
}

.highlight-box {
    background: #e8f4fc;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1.5rem 0;
}

/* Dashboard Section */
.dashboard {
    margin-top: 2rem;
}

.dashboard-item {
    background: #faf6f1;
    border-radius: 12px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 6px 6px rgba(0,0,0,0.1);
    padding-bottom: 4rem;
}

.dashboard-item h3 {
    margin-bottom: 1rem;
    color: #2c3e50;
}

.language-charts {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.language-chart {
    width: 5rem;
    height: 5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.language-chart canvas {
    width: 100%;
    height: 100%;
}

.chart-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem;
    color: #2c3e50;
    text-align: center;
}

.chart-name {
    margin-top: 0.6rem;
    font-size: 0.9rem;
    color: #2c3e50;
    text-align: center;
}

/* Make sure the main content grows if possible */
.about-container {
    flex: 1;
}

@media (max-width: 768px) {
    .about-container {
        flex-direction: column;
    }

    .about-image {
        position: static;
        margin-bottom: 2rem;
    }

    nav ul {
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }
}
