.photo-gallery-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  max-width: 100%; /* Let the container be responsive */
  box-sizing: border-box;
}

.photo-gallery-arrow {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem 0rem;
  color: #333;
  z-index: 2;
  transition: background 0.3s;
}

.photo-gallery-arrow:hover {
  background: rgba(255, 255, 255, 1);
}

.left-arrow {
  margin-right: 0.5rem;
}

.right-arrow {
  margin-left: 0.5rem;
}

.photo-gallery-viewport {
  overflow: hidden;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.photo-gallery-track {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  /* No fixed width here; the transform handles positioning */
}

/* Each slide will be 60% of viewport width, with margins for spacing.
   We'll handle this in the inline style from JS as it depends on viewport width.
*/
.photo-gallery-slide {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  /* margin and width will be set dynamically in JS */
}

.photo-gallery-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 550px; /* Adjust as needed for desired max image height */
  border-radius: 5%;
  background-color: blanchedalmond;
}

.photo-gallery-caption {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #555;
  text-align: center;
}

/* For smaller screens, we can reduce max-height to keep the image fully visible */
@media (max-width: 800px) {
  .photo-gallery-image {
    max-height: 400px;
  }
}
