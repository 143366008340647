
.hero-section {
    background: #faf6f1;
    padding: 4rem 0;
    text-align: center;
}

.hero-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 2rem;
}

.hero-section h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.hero-section p {
    font-size: 1.1rem;
    color: #555;
    max-width: 700px;
    margin: 0 auto;
}

.journey-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
}

.journey-story {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
    margin-bottom: 4rem;
    background: #f9f9f9;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;
}

.journey-story:hover {
    transform: translateY(-5px);
}

.journey-story:nth-child(even) {
    direction: rtl;
}

.journey-story:nth-child(even) .story-image,
.journey-story:nth-child(even) .story-text {
    direction: ltr;
}

.story-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.story-text {
    padding: 2rem;
}

.story-text h2 {
    color: #3498db;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.skills-gained {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.skill-tag {
    background: #b88e72;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}




@media (max-width: 1024px) {
    .journey-story {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .journey-story:nth-child(even) {
        direction: ltr;
    }

    .story-image {
        height: 300px;
    }
}

@media (max-width: 768px) {
    .hero-section {
        padding: 2rem 1rem;
    }

    .hero-section h1 {
        font-size: 2rem;
    }

    .journey-section {
        padding: 2rem 1rem;
    }

    .story-text {
        padding: 1.5rem;
    }
}