.life-section {
  align-self: center;
  max-width: 1200px;
  margin: 4rem;
}

.life-grid {
display: grid;
grid-template-columns: 1fr;
gap: 2rem;
margin-top: 2rem;
}

@media (min-width: 768px) {
.life-grid {
  grid-template-columns: 1fr 1fr;
}
}

.life-card {
display: flex;
flex-direction: column; /* Change to column for mobile */
background: white;
border-radius: 12px;
overflow: hidden;
box-shadow: 0 4px 6px rgba(0,0,0,0.1);
transition: transform 0.3s;
min-height: 50px;
max-height: none; /* Remove max-height constraint */
}

@media (min-width: 768px) {
.life-card {
  flex-direction: row; /* Back to row for larger screens */
  max-height: 300px;
}
}

.life-card:hover {
transform: translateY(-5px);
}

.life-image {
flex: 0 0 auto; /* Allow flexible sizing */
width: 100%;
height: 250px; /* Fixed height for mobile */
background: #f0f0f0;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}

@media (min-width: 768px) {
.life-image {
  flex: 0 0 250px;
  width: auto;
  height: auto;
  max-height: 330px;
}
}

.life-image img {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 0; /* Reset border radius */
}

@media (min-width: 768px) {
.life-image img {
  border-radius: 12px 0 0 12px;
}
}

.life-image svg {
font-size: 4rem;
color: #764ba2;
}

.life-content {
flex: 1;
padding: 1.5rem;
}

.life-content h3 {
color: #764ba2;
margin-bottom: 1rem;
}

.quote-section {
background: #f8f9fa;
padding: 2rem;
margin: 2rem 0;
border-radius: 12px;
text-align: center;
font-style: italic;
color: #555;
}