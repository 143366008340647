.travel-timeline-container {
    position: fixed;
    left: 3rem;
    top: 55%;
    transform: translateY(-50%);
    background: white;
    padding: 1rem;
    border-radius: 8px;
    /* box-shadow: 0 4px 4px rgba(0,0,0,0.1); */
    max-height: 60vh;
    min-width: 300px;
    width: 300px;
    overflow-y: auto;
    z-index: 10;
}

.travel-timeline {
    position: relative;
    padding-left: 20px; /* Space for the vertical line */
    
}

.travel-timeline-vertical-line {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #ddd;
}

.travel-timeline-item {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 10px; /* Space for the dot */
    box-shadow: 0 4px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;

}

.travel-timeline-item:hover {
    transform: translateX(5px);
}

.travel-timeline-dot {
    position: absolute;
    left: -26px; /* Positioned to the left of the timeline line */
    top: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #764ba2;
    border: 2px solid white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.travel-timeline-content {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 0.6rem;
    position: relative;
}

.travel-timeline-date {
    font-size: 0.75rem;
    color: #666;
    margin-bottom: 0.1rem;
    display: block;
}

.travel-timeline-title {
    font-size: 0.9rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.1rem;
}

.travel-timeline-description {
    font-size: 0.8rem;
    color: #666;
    line-height: 1.6;
}

.travel-timeline-item:hover .travel-timeline-content {
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
}


@media (max-width: 1900px) {

    .travel-timeline-container {
        display: none;
    }

    .travel-timeline-item {
        margin-bottom: 2rem;
    }
}