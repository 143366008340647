.travel-container {
    max-width: 1200px;
    position: relative;
    margin: 3rem auto;
    flex: 1;
    padding: 2rem;
}
  
.destination-category {
    margin-bottom: 4rem;
}
  
  .category-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #764ba2;
  }
  
  .category-header h2 {
    color: #764ba2;
    margin: 0;
  }
  
  .category-header .location-count {
    margin-left: 1rem;
    background: #667eea;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 15px;
    font-size: 0.9rem;
  }
  
  .travel-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  @media (max-width: 768px) {
    .travel-grid {
      grid-template-columns: 1fr;
    }
  }