
.skills-grid {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
}

.skill-card {
    background: #faf6f1;
    padding: 2rem;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s;
}

.skill-card:hover {
    transform: translateY(-5px);
}

.skill-card h3 {
    margin-bottom: 1rem;
    color: #2c3e50;
}

.skill-card p {
    color: #555;
}

.projects-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.project-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0rem;
}

.project-card:hover {
    transform: translateY(-5px);
}

.project-image-container {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.project-content {
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.project-content h3 {
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.project-content p {
    flex-grow: 1;
    color: #555;
}

.project-tag {
    display: inline-block;
    background: #b88e72;
    color: #ffffff;
    padding: 0.3rem 0.6rem;
    margin: 0.2rem;
    border-radius: 12px;
    font-size: 0.8rem;
    margin-right: 0.5rem;
}

.project-content a {
    display: inline-block;
    background: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background 0.3s;
    text-align: center;
    margin-top: auto;
}

.project-content a:hover {
    background: #2980b9;
}

/* Achievements Section */
.achievements-section {
    padding: 4rem 2rem;
    background: #ffffff;
}

.achievements-section h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    padding-bottom: 0.5rem;
}

.achievements-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: #3498db;
}

.achievements-list {
    max-width: 800px;
    margin: 0 auto;
    list-style: none;
    padding: 0;
}

.achievement-item {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    position: relative;
}

.achievement-item::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #3498db;
    font-size: 1.5rem;
    top: 0;
}

.achievement-item h3 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.achievement-item p {
    color: #555;
}