/* Header Section Global Styles */
.page-header {
  background: #faf6f1;
  color: #2c3e50;
  text-align: center;
  padding: 1rem 0;
}

.page-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.page-header h4 {
  font-weight: 400;
  color: #555;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .page-header h1 {
      font-size: 2.5rem;
  }

  .page-header h4 {
      font-size: 0.9rem;
  }
}