 /* Social Links */
.social-links {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 0rem;
}
  
.social-links a {
  color: #2980b9;
  font-size: 1.8rem;
  transition: transform 0.3s, color 0.3s;
}
  
.social-links a:hover {
  transform: translateY(-5px);
  color: #2980b9;
}

@media (max-width: 768px) {
    
  .social-links {
    justify-content: center;
  }
    
  .contact-section {
    padding: 2rem 1rem;
  }
    
}
      