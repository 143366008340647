/* Education Page Styles */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

/* Container adjusts to leave space for the fixed timeline */
.container {
    margin-left: 320px; /* 300px timeline width + 20px padding */
    padding: 0.5rem;
    max-width: 1200px;
    margin: 0rem auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: center;
}

.text-section {
    position: relative;
    width: 100%;
}

/* Responsive Adjustments */
@media (max-width: 1400px) {
    .timeline-section {
        display: none; /* Hide the timeline on smaller screens */
    }
    .container {
        margin: 1rem;
        align-items: center;
    }
}