.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    max-width: 800px; /* Adjust for responsiveness */
    width: 100%;
    box-shadow: 0 8px 8px rgba(0,0,0,0.1);
    padding: 2rem;
    border-radius: 12px;
    align-content: center;
    margin-top: 5rem;
}

.contact-method {
    margin-bottom: 0rem;
}

.contact-method h2 {
    color: #333;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0rem;
}

.contact-method p {
    color: #666;
    margin-left: 0; /* Remove left margin for better centering */
}

@media (max-width: 768px) {
    .contact-container {
        padding: 1rem;
    }

    nav ul {
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }
}
