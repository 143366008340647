.travel-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s;
    padding: 0rem;
  }
  
  .travel-card:hover {
    transform: translateY(-5px);
  }

.travel-content {
  padding: 1.5rem;
}
  
.travel-image {
    width: 100%;
    height: 200px; /* Change from fixed height to auto */
    object-fit: cover; /* Ensure it maintains aspect ratio */
}

.travel-image img {
  width: 100%;
  height: 100%; /* Ensures the image scales proportionally */
  object-fit: cover; /* Maintains aspect ratio and covers the container */
}
  
.travel-date {
    color: #666;
    font-size: 0.9rem;

}
  
.travel-title {
    color: #333;
    font-size: 1.25rem;
    margin-bottom: 0rem;

}
  
.travel-excerpt {
    color: #666;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
  
.travel-tags {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

}
  
.travel-tag {
    background: #f0f0f0;
    padding: 0.25rem 0.75rem;
    border-radius: 15px;
    font-size: 0.8rem;
    color: #666;
}
  
  .travel-read-more {
    display: inline-block;
    margin-top: 1rem;
    color: #667eea;
    text-decoration: none;
    font-weight: 500;
  }
  
  .travel-read-more:hover {
    text-decoration: underline;
  }