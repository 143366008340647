/* TravelBlogPage.css */
.blog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
  }
  
  .featured-image-container {
    margin-bottom: 30px;
    width: 100%; /* Ensures container is responsive */
    margin-left: auto;
    margin-right: auto; /* Centers the container */
  }
  
  .featured-image {
    width: 100%;
    height: auto;
    max-height: 400px; /* Adjust as needed */
    object-fit: cover;
    border-radius: 8px;
  }
  
  .image-caption {
    text-align: center;
    font-style: italic;
    color: #666;
    margin-top: 10px;
  }
  
  .blog-section {
    margin-bottom: 30px;
  }
  
  .blog-section h2 {
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    justify-items: center;
    margin-bottom: 30px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    max-height: 300px;
  }
  
  .day-plan {
    margin-bottom: 20px;
  }
  
  .day-plan h3 {
    color: #444;
    margin-bottom: 10px;
  }
  
  .day-plan ol {
    padding-left: 30px;
  }
  
  .day-plan li {
    margin-bottom: 10px;
  }
  
  .day-plan li i {
    margin-right: 10px;
    color: #666;
  }
  
  .travel-tips {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .travel-tips h2 {
    margin-bottom: 15px;
  }
  
  .tip-item {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .author-section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    padding: 10px;
    border-radius: 8px;
  }
  
  .author-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }
  
  .share-section {
    text-align: center;
    margin-top: 30px;
  }
  
  .share-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .share-button {
    font-size: 24px;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .share-button:hover {
    color: #007bff;
  }

  .accommodation-plan {
    margin-bottom: 30px;
    white-space: pre-line;
  }
  
  .accommodation-plan h3 {
    color: #764ba2;
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
  
  .accommodation-plan h4 {
    color: #444;
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
  
  .accommodation-bullets {
    list-style-type: disc;
    padding-left: 30px;
    margin-bottom: 20px;
  }
  
  .accommodation-bullets li {
    margin-bottom: 10px;
  }
.star-rating {
  color: #ffc107;
  margin-bottom: 1rem;
}

.star-rating i {
  margin-right: 0.25rem;
}

@media (max-width: 768px) {
  .accommodation-container {
    flex-direction: column;
  }

  .accommodation-image,
  .accommodation-details {
    width: 100%;
  }

  .accommodation-image {
    height: 250px;
  }
}
