/* Skiing Timeline Specific Styles */
.skiing-main {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 30% 70%; /* Adjusted from 1fr 1fr to 30% 70% */
  gap: 2rem;
  position: relative;
}

  .ski-container{
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 2%;
    background-color: #f8f9fa;
    margin: 2rem;
  }
  
  .skiing-timeline {
    position: relative;
    padding-left: 2rem;
  }
  
  .skiing-timeline::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #333;
  }
  
  .skiing-timeline-item {
    position: relative;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  
  .skiing-timeline-item::before {
    content: '';
    position: absolute;
    left: -2.4rem;
    top: 1rem;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #333;
    border: 2px solid white;
    transition: all 0.3s ease;
  }
  
  .skiing-timeline-item:hover::before {
    background: #007bff;
    transform: scale(1.2);
  }
  
  .skiing-timeline-content {
    background: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .skiing-timeline-preview {
    padding: 0.75rem;
    cursor: pointer;
  }
  
  .skiing-timeline-preview h3 {
    font-size: 1rem;
    margin: 0;
  }
  
  .skiing-timeline-preview p {
    margin: 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  .skiing-timeline-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background: white;
  }
  
  .skiing-timeline-content.expanded {
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    transform: scale(1.02);
  }
  
  .skiing-timeline-content.expanded .skiing-timeline-details {
    max-height: 500px;
    padding: 1rem;
  }
  
  .skiing-timeline-details img {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .skiing-timeline-details p {
    margin-bottom: 0.5rem;
  }
  
  .skiing-gallery-section{
    padding: 2rem;
    border-radius: 2%;
  }

  .skiing-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .skiing-gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .skiing-gallery-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .skiing-gallery-item:hover img {
    transform: scale(1.1);
  }
  
  .skiing-gallery-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    color: white;
    padding: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .skiing-main {
      grid-template-columns: 1fr;
    }
  
    .skiing-gallery {
      grid-template-columns: 1fr;
    }
  }