.project-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
  }
  
  .featured-image-container {
    margin-bottom: 30px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .featured-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .image-caption {
    text-align: center;
    font-style: italic;
    color: #666;
    margin-top: 10px;
  }
  
  .project-section {
    margin-bottom: 30px;
  }
  
  .project-section h2 {
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    justify-items: center;
    margin-bottom: 30px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    max-height: 300px;
  }
  
  .project-phase {
    margin-bottom: 20px;
  }
  
  .project-phase h3 {
    color: #444;
    margin-bottom: 10px;
  }
  
  .project-phase ol {
    padding-left: 30px;
  }
  
  .project-phase li {
    margin-bottom: 10px;
  }
  
  .project-phase li i {
    margin-right: 10px;
    color: #666;
  }
  
  .engineering-insights {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .engineering-insights h2 {
    margin-bottom: 15px;
  }
  
  .insight-item {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .author-section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
  }
  
  .author-info h4 {
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .materials-item {
    margin-bottom: 30px;
    white-space: pre-line;
  }
  
  .materials-item h3 {
    color: #2a7aba;
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
  
  .materials-bullets {
    list-style-type: disc;
    padding-left: 30px;
    margin-bottom: 20px;
  }
  
  .materials-bullets li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .materials-container {
      flex-direction: column;
    }
  
    .materials-image,
    .materials-details {
      width: 100%;
    }
  
    .materials-image {
      height: 250px;
    }
  }
  