  /* Intro Section */
  .intro-section {
    padding: 4rem 0;
    background: #faf6f1; /* Warm, soft off-white */
  }
  
  .intro-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
    padding: 0 2rem;
  }
  
  .profile-img {
    width: 100%;
    height: auto;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    transition: transform 0.3s;
    background: #f0f0f0;
  }
  
  .intro-text h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #2c3e50;
  }
  
  /* Typewriter Effect */
  .typewriter {
    font-family: monospace;
    white-space: pre-wrap;

  }
  
  .cursor {
    display: inline-block;
    width: 2px;
    height: 1em;
    background-color: black;
    animation: blink 0.7s step-end infinite;
    vertical-align: bottom;
  }
  
  @keyframes blink {
    50% {
      background-color: transparent;
    }
  }
  
 
  /* Main Content */
  .Home-main {
    max-width: 1200px;
    margin: 4rem auto;
    padding: 0 2rem;
  } 
  
  .section-title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 0.5rem;
    text-align: center;
  }
  
  .section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: #3498db;
  }
  
  /* Engineering Experience Cards */
  .engineering-experience-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .experience-card {
    background: #faf6f1;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .experience-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  /* Experience Card Sections */
  .experience-top {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .experience-image-container-vertical {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 500px;
  }
  
  .experience-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .experience-company-description h3 {
    color: #2c3e50;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }
  
  .experience-duration {
    font-size: 0.9rem;
    color: #7f8c8d;
    margin-bottom: 1rem;
  }
  
  .experience-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .experience-details {
    margin-top: 1rem;
    padding-left: 1.5rem;
  }
  
  .experience-details li {
    margin-bottom: 0.5rem;
    list-style-type: disc;
  }
  
  .experience-bottom-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
  
  .read-more {
    flex: 1;
    padding: 0.5rem 1rem;
    background: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background 0.3s;
  }
  
  .read-more:hover {
    background: #2980b9;
  }
  
  .key-skills-container {
    flex: 2;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  .skill-tag {
    background: #b88e72;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Personal Life Section */
  .personal-life-section {
    background: #faf6f1;
    width: 100%;
    padding: 4rem 0;
  }
  
  .full-width-bg {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 0.5rem;
  }
  
  .personal-life-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 2rem 0rem;
  }
  
  .personal-life-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
  }
  
  .personal-life-card:hover {
    transform: translateY(-5px);
  }
  
  .preview-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .preview-content h3 {
    color: #2c3e50;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .preview-content p {
    margin-bottom: 1rem;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .intro-content {
      grid-template-columns: 1fr;
      text-align: center;
    }
  
    .profile-img {
      max-width: 80%;
      margin: 0 auto;
    }
  
    .preview-grid, .engineering-experience-cards, .personal-life-grid {
      grid-template-columns: 1fr;
    }
  
    .experience-top, .experience-bottom {
      grid-template-columns: 1fr;
    }
  
    .intro-section, .personal-life-section, .contact-section {
      padding: 2rem 0rem;
    }
  
    .experience-bottom-links {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
  
    .read-more {
      width: 100%;
      text-align: center;
    }
  
    .key-skills-container {
      justify-content: center;
    }
  }
  
  