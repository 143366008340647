/* EducationItem Styles */
.education-item {
    max-width: 800px;
    padding: 1rem;
    margin-bottom: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 8px 8px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    gap: 1rem;
    scroll-margin-top: 200px;
    position: relative;
}

.education-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.education-item.active {
    border-left: 4px solid #3b82f6;
}

.school-logo {
    width: 100%;
    max-width: 140px;
    height: auto;
    max-height: 140px;
    object-fit: contain;
    border-radius: 8px;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.education-content {
    flex: 1;
}

.year {
    color: #6b7280;
    margin-bottom: 0.2rem;
}

.event-item {
    font-size: 0.9rem;
    color: #6b7280;
    padding: 0.1rem 0;
}

.expanded-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.education-item.active .expanded-content {
    max-height: 1000px; /* Arbitrary large value to allow expansion */
}

.expanded-content h4 {
    margin-top: 0.5rem;
    color: #2c3e50;
}

.expanded-content ul {
    list-style-type: disc;
    margin-left: 1.5rem;
}

.expanded-content li {
    margin-bottom: 0rem;
}

/* Responsive layout for small screens */
@media (max-width: 768px) {
    .education-item {
        flex-direction: column;
        align-items: center;

    }

    .school-logo {
        max-width: 140px;
        margin-bottom: 1rem;
    }

    .education-content {
        width: 100%;

    }
}